import { Box, Button, Typography, Stack, useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Link from "components/Link";
import SubscriptionDialog from "components/SubscriptionDialog";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import JSONBig from "json-bigint";
import utils from "utils";
import { setSaved } from "state";
import PlaylistDialog from "./PlaylistDialog";

const Product = ({
  product: {
    id,
    title,
    website,
    internalNumber,
    videoId,
    thumbnailUrl,
    galleryCount,
    models,
    tags,
    isSaved,
    playlistIds,
    createdAt,
  },
  isLoggedIn,
}) => {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  let [searchParams, setSearchParams] = useSearchParams();

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const modelsLink = [];
  const tagsLink = [];
  for (const model of models) {
    let added = false;
    for (let i = 0; i < state.models.length; i++) {
      if (state.models[i].name === model) {
        modelsLink.push({ page: 1, model: i });
        added = true;
        break;
      }
    }
    if (!added) {
      modelsLink.push(null);
    }
  }
  for (const tag of tags) {
    let added = false;
    for (let i = 0; i < state.tags.length; i++) {
      if (state.tags[i].name === tag) {
        tagsLink.push({ page: 1, tag: i });
        added = true;
        break;
      }
    }
    if (!added) {
      tagsLink.push(null);
    }
  }
  const [isProductSaved, setIsProductSaved] = useState(isSaved);
  const [isSavedDisable, setIsSavedDisable] = useState(false);

  useEffect(() => {
    setIsProductSaved(isSaved);
  }, [id]);

  const showSubButton = videoId == -1 && galleryCount == -1;

  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);

  const closeSubscriptionDialog = () => {
    setShowSubscriptionDialog(false);
  };

  const [showPlaylistDialog, setShowPlaylistDialog] = useState(false);

  const closePlaylistDialog = () => {
    setShowPlaylistDialog(false);
  };

  const [showPlaylist, setShowPlaylist] = useState(false);

  const closePlaylist = () => {
    setShowPlaylist(false);
  };

  const buySubscriptionOnClick = () => {
    setShowSubscriptionDialog(!showSubscriptionDialog);
  };

  const savedOnClick = async () => {
    setIsSavedDisable(true);
    try {
      if (isProductSaved) {
        await deleteSaveRequest();
      } else {
        await saveRequest();
      }
    } catch (error) {}
    setIsSavedDisable(false);
  };

  const saveRequest = async () => {
    const resp = await fetch(BaseURL + "/user/saved", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
      body: JSONBig.stringify({ productId: BigInt(id) }),
    });
    if (resp.status == 200) {
      setIsProductSaved(true);
      dispatch(setSaved({ productId: id, isSaved: true }));
    }
  };

  const deleteSaveRequest = async () => {
    const resp = await fetch(BaseURL + "/user/saved/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
    });
    if (resp.status == 200) {
      setIsProductSaved(false);
      dispatch(setSaved({ productId: id, isSaved: false }));
    }
  };

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  return (
    <>
      <Box
        className="entity"
        backgroundColor="#303134"
        marginTop="2rem"
        sx={{ borderRadius: "16px" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          marginLeft="1rem"
          marginRight="1rem"
        >
          <Typography
            variant={isNonMobileScreens ? "h4" : "h5"}
            marginTop="0.5rem"
            marginBottom="0.2rem"
          >
            #{internalNumber}
          </Typography>
          <Typography
            variant={isNonMobileScreens ? "h4" : "h5"}
            marginTop="0.5rem"
            marginBottom="0.2rem"
          >
            {website}
          </Typography>
        </Stack>
        <Box
          className="entity-img"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          marginTop="0.3rem"
        >
          <img
            src={thumbnailUrl}
            alt="alternative"
            width="100%"
            height="auto"
          />
        </Box>
        <Stack spacing={2} direction="row">
          {showSubButton ? (
            isLoggedIn ? (
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={buySubscriptionOnClick}
                sx={{ borderRadius: "16px" }}
              >
                خرید اشتراک
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                fullWidth
                href="/auth"
                // onClick={signupOnClick}
                sx={{ borderRadius: "16px" }}
              >
                ثبت نام
              </Button>
            )
          ) : (
            <>
              <Button
                variant="contained"
                color="success"
                disabled={videoId == 0}
                fullWidth
                href={`/video/${videoId}`}
                // onClick={watchOnClick}
                sx={{ borderRadius: "16px" }}
              >
                {videoId == 0 ? "کلیپ ندارد" : "تماشا"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!galleryCount}
                fullWidth
                href={`/gallery/${id}`}
                // onClick={galleryOnClick}
                sx={{ borderRadius: "16px" }}
              >
                گالری ({galleryCount})
              </Button>
              <Button
                variant="contained"
                color="warning"
                disabled={isSavedDisable}
                fullWidth
                onClick={() => setShowPlaylistDialog(true)}
                sx={{ borderRadius: "16px" }}
              >
                ذخیره
              </Button>
              {/* <Button
                variant={isProductSaved ? "outlined" : "contained"}
                color={isProductSaved ? "warning" : "warning"}
                disabled={isSavedDisable}
                fullWidth
                onClick={savedOnClick}
                sx={{ borderRadius: "16px" }}
              >
                {isProductSaved ? "ذخیره شده" : "ذخیره"}
              </Button> */}
            </>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          marginLeft="1rem"
          marginRight="1rem"
        >
          <Box className="entity-website" marginLeft="1%" marginBottom="0.5rem">
            <Stack direction="row" spacing={1} marginTop="0.75rem">
              {models.map((model, index) => (
                <Link
                  key={index}
                  variant="h5"
                  underline="none"
                  href={
                    modelsLink[index]
                      ? `/home${utils.getQueryString(modelsLink[index])}`
                      : ""
                  }
                  onClick={() => {
                    if (modelsLink[index]) {
                      setSearchParams(modelsLink[index]);
                      window.scrollTo(0, 0);
                    }
                  }}
                  // sx={{ cursor: "pointer" }}
                >
                  {model}
                  {index !== models.length - 1 ? "," : ""}
                </Link>
              ))}
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              paddingTop="0.75rem"
              paddingBottom="0.5rem"
            >
              {tags.map((tag, index) => (
                <Link
                  key={index}
                  variant="h5"
                  underline="none"
                  href={
                    tagsLink[index]
                      ? `/home${utils.getQueryString(tagsLink[index])}`
                      : ""
                  }
                  onClick={() => {
                    if (tagsLink[index]) {
                      setSearchParams(tagsLink[index]);
                      window.scrollTo(0, 0);
                    }
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {tag}
                  {index !== tags.length - 1 ? "," : ""}
                </Link>
              ))}
            </Stack>
          </Box>
        </Stack>
        <SubscriptionDialog
          open={showSubscriptionDialog}
          onClose={closeSubscriptionDialog}
        ></SubscriptionDialog>
        <PlaylistDialog
          open={showPlaylistDialog}
          onClose={closePlaylistDialog}
          productId={id}
          productPlaylistIds={playlistIds}
          isSaved={isSaved}
        ></PlaylistDialog>
      </Box>
    </>
  );
};

export default Product;
