import { Box, useMediaQuery, Pagination } from "@mui/material";
import Navbar from "scenes/navbar";
import DialogBox from "components/DialogBox";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import JSONBig from "json-bigint";

const GalleryPage = () => {
  const BaseURL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const [dialogData, setDialogData] = useState({
    title: "",
    content: "",
    close: {
      action: null,
      closeOnClick: true,
    },
    buttons: [],
  });
  const [showDialog, setShowDialog] = useState(false);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [galleryUrls, setGalleryUrls] = useState([]);
  const [visibleGalleryUrls, setVisibleGalleryUrls] = useState([]);

  let [searchParams, setSearchParams] = useSearchParams();
  let urlPage = searchParams.get("page");
  let pageForState = Number(urlPage) || 1;
  if (isNaN(pageForState) || pageForState < 1) {
    pageForState = 1;
  }
  const [page, setPage] = useState(pageForState);
  const handlePageChange = (event, value) => {
    setPage(value);
    const queryParams = {};
    queryParams["page"] = value;
    setSearchParams(queryParams);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setVisibles();
  }, [urlPage, galleryUrls]);

  let { productId } = useParams();

  useEffect(() => {
    getGallery();
  }, []);

  const setVisibles = () => {
    // console.log("length: ", galleryUrls.length, " page: ", urlPage);
    let page = Number(urlPage) || 1;
    if (isNaN(page) || page < 1) {
      page = 1;
    }
    let bottomRange = (page - 1) * 10;
    let topRange = bottomRange + 10;

    // console.log("bottomRange ", bottomRange, " topRange", topRange);

    if (galleryUrls && galleryUrls.length > 0) {
      if (bottomRange > galleryUrls.length - 1) {
        setVisibleGalleryUrls(
          galleryUrls.slice(0, min(10, galleryUrls.length))
        );
      } else if (topRange > galleryUrls.length) {
        setVisibleGalleryUrls(
          galleryUrls.slice(bottomRange, galleryUrls.length)
        );
      } else {
        setVisibleGalleryUrls(galleryUrls.slice(bottomRange, topRange));
      }
    }
  };

  const getGallery = async () => {
    try {
      const resp = await fetch(BaseURL + "/product/gallery/" + productId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.token,
        },
      });

      const respStr = await resp.text();
      const json = JSONBig.parse(respStr);
      if (json.error) {
        throw json.error;
      } else {
        if (json.data && json.data.galleryUrls) {
          setGalleryUrls(json.data.galleryUrls);
        }
      }
    } catch (e) {
      // console.log("get gallery error: ", e);
      handleError(e);
    }
  };

  const handleError = (err) => {
    if (err && err.internalCode) {
      if (
        err.internalCode == 4010 ||
        err.internalCode == 4011 ||
        err.internalCode == 4043
      ) {
        dispatch(setLogout());
        navigate("/auth", { replace: true });
        return;
      } else if (err.internalCode == 4032) {
        setDialogData({
          title: "خطا",
          content: err.message,
          close: {
            action: () => undefined,
            closeOnClick: false,
          },
          buttons: [
            {
              text: "تایید",
              action: () => {
                navigate("/auth", { replace: true });
              },
              closeOnClick: true,
            },
          ],
        });
        setShowDialog(true);
        return;
      } else if (err.internalCode == 4033) {
        setDialogData({
          title: "خطا",
          content: err.message,
          close: {
            action: () => undefined,
            closeOnClick: false,
          },
          buttons: [
            {
              text: "تایید",
              action: () => {
                navigate("/auth", { replace: true });
              },
              closeOnClick: true,
            },
          ],
        });
        setShowDialog(true);
        return;
      }
    }
    setDialogData({
      title: "خطا",
      content: "خطایی رخ داد. لطفا مجددا تلاش کنید",
      close: {
        action: () => undefined,
        closeOnClick: true,
      },
      buttons: [
        {
          text: "تایید",
          action: () => undefined,
          closeOnClick: true,
        },
      ],
    });
    setShowDialog(true);
  };

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  return (
    <Box>
      <Navbar />

      <DialogBox
        open={showDialog}
        setOpen={setShowDialog}
        title={dialogData.title}
        content={dialogData.content}
        close={dialogData.close}
        buttons={dialogData.buttons}
      ></DialogBox>

      <Box
        className="container"
        width={isNonMobileScreens ? "60%" : "90%"}
        marginTop="1rem"
        marginLeft={isNonMobileScreens ? "20%" : "5%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Pagination
          count={
            galleryUrls && galleryUrls.length > 0
              ? Math.floor((galleryUrls.length - 1) / 10) + 1
              : 0
          }
          page={page}
          siblingCount={5}
          onChange={handlePageChange}
        />
        {visibleGalleryUrls.map((x, index) => (
          <Box
            key={index}
            className="entity-img"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            marginTop="2rem"
            marginBottom="2rem"
          >
            <img src={x} alt="alternative" width="100%" height="auto" />
          </Box>
        ))}
        <Pagination
          count={
            galleryUrls && galleryUrls.length > 0
              ? Math.floor((galleryUrls.length - 1) / 10) + 1
              : 0
          }
          page={page}
          siblingCount={5}
          onChange={handlePageChange}
        />
      </Box>
      <Box height="5rem"></Box>
    </Box>
  );
};

export default GalleryPage;

function min(a, b) {
  if (a < b) {
    return a;
  }
  return b;
}
