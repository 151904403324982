function getDomainName() {
  return process.env.REACT_APP_BASE_DOMAIN || "https://privatestream.app";
}

function getQueryString(query) {
  let result = "?";
  if (!query) {
    return "";
  }

  for (const key in query) {
    result += `${key}=${query[key]}&`;
  }
  result = result.slice(0, -1);

  return result;
}

function processProducts(products) {
  return products.map((x) => {
    x.id = x.id.toString();
    x.videoId = x.videoId.toString();
    x.playlistIds = x.playlistIds.map(pid => pid.toString())
    return x;
  });
}

module.exports = {
  getDomainName,
  getQueryString,
  processProducts
};
