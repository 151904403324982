import { useMediaQuery } from "@mui/material";

// Revised color design tokens for charcoal gray and light green theme
export const colorTokens = {
  grey: {
    0: "#FFFFFF",
    10: "#F2F2F2", // Lighter gray for background
    50: "#E0E0E0", // Soft gray
    100: "#CBCBCB", // Medium light gray
    200: "#B6B6B6", // Neutral gray
    300: "#A1A1A1", // Medium gray
    400: "#8C8C8C", // Standard gray
    500: "#777777", // Dark gray
    600: "#626262", // Darker gray
    700: "#4D4D4D", // Charcoal light
    800: "#383838", // Charcoal medium
    900: "#232323", // Charcoal dark
    1000: "#000000", // Black
  },
  primary: {
    50: "#E9FBEA", // Lightest green
    100: "#C4F6C7", // Very light green
    200: "#9FF1A3", // Light green
    300: "#7AED80", // Soft green
    400: "#54E85C", // Medium green
    500: "#2EE337", // Vibrant light green
    600: "#25B62A", // Dark light green
    700: "#1C8A1F", // Darker green
    800: "#135D14", // Very dark green
    900: "#0A310A", // Deepest green
  },
  // Button colors
  button: {
    main: "#2EE337", // Vibrant light green
    hover: "#54E85C", // Medium green
    text: "#000000", // Black for text
  },
};

// Updated MUI theme settings with charcoal gray and light green theme
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              dark: colorTokens.primary[600],
              main: colorTokens.primary[500],
              light: colorTokens.primary[400],
            },
            neutral: {
              dark: colorTokens.grey[300],
              main: colorTokens.grey[500],
              mediumMain: colorTokens.grey[700],
              medium: colorTokens.grey[800],
              light: colorTokens.grey[900],
            },
            background: {
              default: colorTokens.grey[1000],
              alt: colorTokens.grey[900],
            },
            button: {
              main: colorTokens.button.main,
              hover: colorTokens.button.hover,
              text: colorTokens.button.text,
            },
          }
        : {
            primary: {
              dark: colorTokens.primary[700],
              main: colorTokens.primary[500],
              light: colorTokens.primary[300],
            },
            neutral: {
              dark: colorTokens.grey[700],
              main: colorTokens.grey[500],
              mediumMain: colorTokens.grey[300],
              medium: colorTokens.grey[200],
              light: colorTokens.grey[50],
            },
            background: {
              default: colorTokens.grey[50],
              alt: colorTokens.grey[10],
            },
            button: {
              main: colorTokens.button.main,
              hover: colorTokens.button.hover,
              text: colorTokens.button.text,
            },
          }),
    },
    typography: {
      fontFamily: ["Vazir", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Vazir", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Vazir", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Vazir", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Vazir", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Vazir", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Vazir", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
