import { processProducts } from "utils";
import { get, post, put, deleteBe } from "../index";

export async function getPlaylists(token) {
  const resp = await get({
    path: `/user/playlist`,
    token,
  });
  if (resp?.data)
    resp.data = resp.data.map((data) => {
      data.id = data.id.toString();
      data.userId = data.userId.toString();
      return data;
    });
  return resp.data;
}

export async function getPlaylistProducts(token, playlistId, offset, limit) {
  const resp = await post({
    path: `/user/playlist/products`,
    body: {
      playlistId: BigInt(playlistId),
      offset,
      limit,
    },
    token,
  });
  if (resp?.data?.products)
    resp.data.products = processProducts(resp.data.products);
  return resp.data;
}

export async function getSavedProducts(token, offset, limit) {
  const resp = await post({
    path: `/product/saved`,
    body: {
      offset,
      limit,
    },
    token,
  });
  if (resp?.data?.products)
    resp.data.products = processProducts(resp.data.products);
  return resp.data;
}

export async function createPlaylist(token, name) {
  const resp = await post({
    path: `/user/playlist`,
    body: {
      name,
    },
    token,
  });
  return resp.data;
}

export async function deletePlaylist(token, playlistId) {
  const resp = await deleteBe({
    path: `/user/playlist/${playlistId}`,
    token,
  });
}

export async function addProductToPlaylist(token, playlistId, productId) {
  const resp = await post({
    path: `/user/playlist/product`,
    body: {
      playlistId: BigInt(playlistId),
      productId: BigInt(productId),
    },
    token,
  });
}

export async function removeProductFromPlaylist(token, playlistId, productId) {
  const resp = await deleteBe({
    path: `/user/playlist/${playlistId}/product/${productId}`,
    token,
  });
}

export async function saveProduct(token, productId) {
  const resp = await post({
    path: `/user/saved`,
    body: {
      productId: BigInt(productId),
    },
    token,
  });
}

export async function unsaveProduct(token, productId) {
  const resp = await deleteBe({
    path: `/user/saved/${productId}`,
    token,
  });
}
