const API_URL = process.env.REACT_APP_BASE_URL;
import JSONBig from "json-bigint";

const get = async ({ path, params, token }) => {
  const queryString = params
    ? `?${Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&")}`
    : "";

  try {
    const response = await fetch(`${API_URL}${path}${queryString}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
      const respStr = await response.text();
      const data = JSONBig.parse(respStr);
      if (data.error) {
        throw json.error;
      }
      return data;
    } else {
      throw new Error("Non-JSON response received");
    }
  } catch (error) {
    throw error;
  }
};

const post = async ({ path, body, token }) => {
  try {
    const response = await fetch(`${API_URL}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSONBig.stringify(body),
    });

    const respStr = await response.text();
    const data = JSONBig.parse(respStr);
    if (data.error) {
      throw json.error;
    }
    return data;
  } catch (error) {
    throw error;
  }
};

const put = async ({ path, body, token }) => {
  try {
    const response = await fetch(`${API_URL}${path}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSONBig.stringify(body),
    });

    const respStr = await response.text();
    const data = JSONBig.parse(respStr);
    if (data.error) {
      throw json.error;
    }
    return data;
  } catch (error) {
    throw error;
  }
};

const deleteBe = async ({ path, token }) => {
  try {
    const response = await fetch(`${API_URL}${path}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export { get, post, deleteBe, put };
