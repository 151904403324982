import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Pagination,
} from "@mui/material";
import Navbar from "scenes/navbar";
import DialogBox from "components/DialogBox";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ProductComponent from "components/Product";
import { setProducts } from "state";
import { getPlaylistProducts, getSavedProducts } from "api/playlist";

const PlaylistPage = () => {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(null);
  const state = useSelector((state) => state);

  let urlPlaylist = searchParams.get("playlist");

  const getUrlPlaylistValue = () => {
    if (!urlPlaylist || isNaN(urlPlaylist)) {
      return "-1";
    }

    const plCount = state.playlists.length;
    const pl = Number(urlPlaylist);
    if (pl < -1 || pl >= plCount) {
      return "-1";
    }
    return urlPlaylist;
  };

  let urlPage = searchParams.get("page") || 1;
  urlPage = Number(urlPage);
  if (isNaN(urlPage) || urlPage < 1) {
    urlPage = 1;
  }
  const [page, setPage] = useState(urlPage);
  const [selectedPlaylist, setSelectedPlaylist] = useState(
    getUrlPlaylistValue()
  );
  const handlePageChange = (event, value) => {
    setPage(value);
    const queryParams = {};
    for (const key of searchParams.keys()) {
      queryParams[key] = searchParams.get(key);
    }
    queryParams["page"] = value;
    setSearchParams(queryParams);
    window.scrollTo(0, 0);
  };
  const handlePlaylistChange = (event) => {
    setSelectedPlaylist(event.target.value);
    const queryParams = {};
    for (const key of searchParams.keys()) {
      queryParams[key] = searchParams.get(key);
    }
    if (event.target.value !== "") {
      queryParams["playlist"] = event.target.value;
    } else {
      delete queryParams["playlist"];
    }
    queryParams["page"] = 1;
    setSearchParams(queryParams);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setProducts({ products: [], total: 0 });

    getProducts();
  }, [urlPage, urlPlaylist]);

  const getProducts = async () => {
    try {
      const filters = getFilters();
      if (filters.playlistId) {
        const resp = await getPlaylistProducts(
          state.token,
          filters.playlistId,
          filters.offset,
          filters.limit
        );
        dispatch(setProducts({ products: resp.products, total: resp.total }));
      } else {
        const resp = await getSavedProducts(
          state.token,
          filters.offset,
          filters.limit
        );
        dispatch(setProducts({ products: resp.products, total: resp.total }));
      }
    } catch (e) {
      setError(e);
    }
  };

  const getFilters = () => {
    const payload = {
      offset: 0,
      limit: 10,
      playlistId: "",
    };
    const filterObj = {
      playlist: urlPlaylist || "",
      page: urlPage || 1,
    };

    if (!isNaN(filterObj.playlist)) {
      const pl = Number(filterObj.playlist);
      if (
        filterObj.playlist !== "" &&
        state &&
        state.playlists &&
        pl > -1 &&
        pl < state.playlists.length
      ) {
        payload.playlistId = state.playlists[pl].id;
      }
    }
    if (!isNaN(filterObj.page)) {
      const page = Number(filterObj.page);
      if (page > 0) {
        payload.offset = (page - 1) * 10;
      }
    }

    return payload;
  };

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  return (
    <Box>
      <Navbar />
      <DialogBox
        open={showDialog}
        setOpen={setShowDialog}
        title=""
        content=""
        close={{}}
        buttons={[]}
        err={error}
      ></DialogBox>
      <Box
        className="filters-top"
        width={isNonMobileScreens ? "60%" : "90%"}
        marginLeft={isNonMobileScreens ? "20%" : "5%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <FormControl
          sx={{
            margin: "auto",
            mt: 4,
            minWidth: 300,
            width: "80%", // Adjust width as needed
          }}
        >
          <InputLabel id="playlist-select-label">پلی لیست</InputLabel>
          <Select
            labelId="playlist-select-label"
            id="playlist-select"
            value={selectedPlaylist}
            label="پلی لیست"
            onChange={handlePlaylistChange}
          >
            <MenuItem value="-1">
              <em>مورد علاقه ها</em>
            </MenuItem>
            {state.playlists.map((x, index) => (
              <MenuItem value={index} key={index}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box height="2rem"></Box>
      <Box
        className="pagination-top"
        width={isNonMobileScreens ? "60%" : "90%"}
        marginLeft={isNonMobileScreens ? "20%" : "5%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Pagination
          count={
            state.totalProducts > 0
              ? Math.floor((state.totalProducts - 1) / 10) + 1
              : 0
          }
          page={page}
          siblingCount={5}
          onChange={handlePageChange}
          sx={{ display: "table-cell" }}
        />
      </Box>
      <Box
        className="container"
        width={isNonMobileScreens ? "50%" : "90%"}
        marginTop="1rem"
        marginLeft={isNonMobileScreens ? "25%" : "5%"}
        // display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        {state?.products?.length > 0 ? (
          state.products.map((product, index) => (
            <ProductComponent product={product} key={index} />
          ))
        ) : (
          <Box className="empty">
            <h2>No videos found</h2>
          </Box>
        )}
      </Box>
      <Box
        className="pagination"
        width={isNonMobileScreens ? "60%" : "90%"}
        marginTop="1rem"
        marginLeft={isNonMobileScreens ? "20%" : "5%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Pagination
          count={
            state.totalProducts > 0
              ? Math.floor((state.totalProducts - 1) / 10) + 1
              : 0
          }
          page={page}
          siblingCount={5}
          onChange={handlePageChange}
          sx={{ display: "table-cell" }}
        />
      </Box>
      <Box height="5rem"></Box>
    </Box>
  );
};

export default PlaylistPage;
