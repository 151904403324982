import {
  Box,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import JSONBig from "json-bigint";
import DialogBox from "components/DialogBox";

import {
  setProducts,
  setLogout,
  setFilters,
  setAuthData,
  setPlaylists,
} from "state";
import { getPlaylists } from "api/playlist";
import { getAllFilters, getAllProducts } from "api/product";

const SearchBar = ({ setLoggedIn }) => {
  const BaseURL = process.env.REACT_APP_BASE_URL;

  const dispatch = useDispatch();
  const [dialogData, setDialogData] = useState({
    title: "",
    content: "",
    close: {
      action: null,
      closeOnClick: true,
    },
    buttons: [],
  });
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(null);

  let [searchParams, setSearchParams] = useSearchParams();

  const state = useSelector((state) => state);

  let userToken = state.token;

  let urlModel = searchParams.get("model");
  let urlWebsite = searchParams.get("website");
  let urlTag = searchParams.get("tag");
  let urlType = searchParams.get("type");
  let urlPage = searchParams.get("page");

  let [localFilters, setLocalFilters] = useState({
    model: "",
    tag: "",
    website: "",
    type: "",
    page: 1,
  });

  const handleModelChange = (event) => {
    const queryParams = {};
    for (const key of searchParams.keys()) {
      queryParams[key] = searchParams.get(key);
    }
    if (event.target.value !== "") {
      queryParams["model"] = event.target.value;
    } else {
      delete queryParams["model"];
    }
    queryParams["page"] = 1;
    setSearchParams(queryParams);
  };
  const handleWebsiteChange = (event) => {
    const queryParams = {};
    for (const key of searchParams.keys()) {
      queryParams[key] = searchParams.get(key);
    }
    if (event.target.value !== "") {
      queryParams["website"] = event.target.value;
    } else {
      delete queryParams["website"];
    }
    queryParams["page"] = 1;
    setSearchParams(queryParams);
  };
  const handleTagChange = (event) => {
    const queryParams = {};
    for (const key of searchParams.keys()) {
      queryParams[key] = searchParams.get(key);
    }
    if (event.target.value !== "") {
      queryParams["tag"] = event.target.value;
    } else {
      delete queryParams["tag"];
    }
    queryParams["page"] = 1;
    setSearchParams(queryParams);
  };
  const handleTypeChange = (event) => {
    const queryParams = {};
    for (const key of searchParams.keys()) {
      queryParams[key] = searchParams.get(key);
    }
    if (event.target.value !== "") {
      queryParams["type"] = event.target.value;
    } else {
      delete queryParams["type"];
    }
    queryParams["page"] = 1;
    setSearchParams(queryParams);
  };

  useEffect(() => {
    // console.log("one time execution ", searchParams.get("page"), urlPage);
    setLocalFilters({
      model: urlModel || "",
      tag: urlTag || "",
      website: urlWebsite || "",
      type: urlType || "",
      page: urlPage || 1,
    });
    dispatch(setProducts({ products: [], total: 0 }));

    if (state && state.models && state.models.length > 0) {
      getProducts();
    }
  }, [urlModel, urlWebsite, urlTag, urlPage, urlType]);

  useEffect(() => {
    dispatch(setProducts({ products: [], total: 0 }));
    setUpHomeScreen();
  }, []);

  //get filters, get products
  const setUpHomeScreen = async () => {
    try {
      await newTokenAPI();
      await getAllPlaylists();
      await getFilters();
      await getProducts();
    } catch (e) {
      console.log("get filters error: ", e);
      // handleError(e);
      setError(e);
    }
  };

  const newTokenAPI = async () => {
    const now = Math.floor(Date.now() / 1000);
    const secondsTillLastUpdate = now - state.lastTokenUpdate;
    if (state.token && state.user && state.user.email) {
      if (secondsTillLastUpdate > 3600 * 4) {
        //3600 * 4)
        const userEmail = state.user.email;
        const resp = await fetch(BaseURL + "/authorize/new", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.token,
          },
          body: "{}",
        });
        const respStr = await resp.text();
        const json = JSONBig.parse(respStr);
        // console.log(json);
        if (json.error) {
          dispatch(setLogout());
          setLoggedIn(false);
          return;
        }
        if (json && json.data && json.data.userId) {
          userToken = json.data.token;
          dispatch(
            setAuthData({
              user: {
                email: userEmail,
                userId: json.data.userId.toString(),
              },
              token: json.data.token,
            })
          );
          setLoggedIn(true);
        }
      } else {
        setLoggedIn(true);
      }
    } else {
      setLoggedIn(false);
    }
  };

  const getFilters = async () => {
    const resp = await getAllFilters(userToken);
    dispatch(setFilters(resp));
  };

  const getAllPlaylists = async () => {
    try {
      const resp = await getPlaylists(userToken);
      dispatch(setPlaylists({ playlists: resp }));
    } catch (e) {
      setError(e);
    }
  };

  const getProducts = async () => {
    try {
      const resp = await getAllProducts(userToken, getProductBodyGenerator());
      dispatch(setProducts({ products: resp.products, total: resp.total }));
    } catch (e) {
      setError(e);
    }
  };

  const getProductBodyGenerator = () => {
    const payload = {
      modelIds: [],
      tagIds: [],
      websiteIds: [],
      productType: "",
      offset: 0,
      limit: 10,
      latest: true,
    };
    const filterObj = {
      model: urlModel || "",
      tag: urlTag || "",
      website: urlWebsite || "",
      type: urlType || "all",
      page: urlPage || 1,
    };
    if (!isNaN(filterObj.model)) {
      const model = Number(filterObj.model);
      if (
        filterObj.model !== "" &&
        state &&
        state.models &&
        model > -1 &&
        model < state.models.length
      ) {
        payload.modelIds = [BigInt(state.models[model].id)];
      }
    }
    if (!isNaN(filterObj.website)) {
      const website = Number(filterObj.website);
      if (
        filterObj.website !== "" &&
        state &&
        state.websites &&
        website > -1 &&
        website < state.websites.length
      ) {
        payload.websiteIds = [state.websites[website].id];
      }
    }
    if (!isNaN(filterObj.tag)) {
      const tag = Number(filterObj.tag);
      if (
        filterObj.tag !== "" &&
        state &&
        state.tags &&
        tag > -1 &&
        tag < state.tags.length
      ) {
        payload.tagIds = [BigInt(state.tags[tag].id)];
      }
    }
    if (!isNaN(filterObj.page)) {
      const page = Number(filterObj.page);
      if (page > 0) {
        payload.offset = (page - 1) * 10;
      }
    }
    if (filterObj.type === "g" || filterObj.type === "v") {
      payload.productType = filterObj.type;
    }

    // console.log("returning payload for products: ", payload);
    // console.log("page: ", localFilters.page);

    return payload;
  };

  const isNonMobileScreens = useMediaQuery("(min-width:1250px)");

  return (
    <>
      <Box
        display={isNonMobileScreens ? "flex" : "block"}
        justifyContent={isNonMobileScreens ? "center" : undefined}
        alignItems={isNonMobileScreens ? "center" : undefined}
      >
        <DialogBox
          open={showDialog}
          setOpen={setShowDialog}
          title={dialogData.title}
          content={dialogData.content}
          close={dialogData.close}
          buttons={dialogData.buttons}
          err={error}
        ></DialogBox>
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <InputLabel id="model-select-label">مدل</InputLabel>
          <Select
            labelId="model-select-label"
            id="model-select"
            value={localFilters.model}
            label="Model"
            onChange={handleModelChange}
            // renderValue={(value) => `⚠️  - ${value}`}
          >
            <MenuItem value="">
              <em>همه</em>
            </MenuItem>
            {state.models.map((x, index) => (
              <MenuItem value={index} key={index}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <InputLabel id="website-select-label">وب سایت</InputLabel>
          <Select
            labelId="website-select-label"
            id="website-select"
            value={localFilters.website}
            // label="Website"
            onChange={handleWebsiteChange}
            // inputProps={{ readOnly: true }}
          >
            <MenuItem value="">
              <em>همه</em>
            </MenuItem>
            {state.websites.map((x, index) => (
              <MenuItem value={index} key={index}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <InputLabel id="tag-select-label">تگ</InputLabel>
          <Select
            labelId="tag-select-label"
            id="tag-select"
            value={localFilters.tag}
            label="Tag"
            onChange={handleTagChange}
          >
            <MenuItem value="">
              <em>همه</em>
            </MenuItem>
            {state.tags.map((x, index) => (
              <MenuItem value={index} key={index}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <InputLabel id="type-select-label">دسته بندی</InputLabel>
          <Select
            labelId="type-select-label"
            id="type-select"
            value={localFilters.type}
            label="type"
            onChange={handleTypeChange}
          >
            <MenuItem value="">
              <em>همه</em>
            </MenuItem>
            <MenuItem value="v">کلیپ</MenuItem>
            <MenuItem value="g">گالری عکس</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default SearchBar;
