import { Box, useMediaQuery } from "@mui/material";
import Navbar from "scenes/navbar";
import DialogBox from "components/DialogBox";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import VideoJS from "components/VideoJS";
import { useSelector, useDispatch } from "react-redux";
import JSONBig from "json-bigint";

const VideoPage = () => {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const [videoSrc, setVideoSrc] = useState("");

  const navigate = useNavigate();
  const [dialogData, setDialogData] = useState({
    title: "",
    content: "",
    close: {
      action: null,
      closeOnClick: true,
    },
    buttons: [],
  });
  const [showDialog, setShowDialog] = useState(false);

  let { videoId } = useParams();
  const dispatch = useDispatch();
  let poster = "";
  let productId = "";
  let isSaved = false;
  const state = useSelector((state) => state);
  if (state.products) {
    for (let i = 0; i < state.products.length; i++) {
      if (state.products[i].videoId === videoId) {
        poster = state.products[i].thumbnailUrl;
        productId = state.products[i].id;
        isSaved = state.products[i].isSaved;
      }
    }
  }

  const playerRef = useRef(null);

  const [videoJsOptions, setVideoJsOptions] = useState({
    autoplay: false,
    controls: true,
    responsive: true,
    width: "100%",
    aspectRatio: "16:9",
    fluid: true,
    poster: poster,
    // sources: [
    //   {
    //     src: "https://dngfmhhmpgw1j.cloudfront.net/video/mas_MakeHimLick",
    //     type: "video/mp4",
    //   },
    // ],
  });

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      // console.log("player is waiting");
    });

    player.on("dispose", () => {
      // console.log("player will dispose");
    });
  };

  useEffect(() => {
    getVideo();
  }, []);

  const getVideo = async () => {
    try {
      const resp = await fetch(BaseURL + "/product/video/" + videoId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.token,
        },
      });

      const respStr = await resp.text();
      const json = JSONBig.parse(respStr);
      if (json.error) {
        throw json.error;
      } else {
        if (json.data) {
          setVideoSrc(json.data.url + `?token=${json.data.token}`);
          setVideoJsOptions({
            autoplay: false,
            controls: true,
            responsive: true,
            width: "100%",
            aspectRatio: "16:9",
            fluid: true,
            poster: poster,
            sources: [
              {
                src: json.data.url + `?token=${json.data.token}`,
                type: "video/mp4",
              },
            ],
          });
        }
      }
    } catch (e) {
      // console.log("get gallery error: ", e);
      handleError(e);
    }
  };

  const handleError = (err) => {
    if (err && err.internalCode) {
      if (
        err.internalCode == 4010 ||
        err.internalCode == 4011 ||
        err.internalCode == 4043
      ) {
        dispatch(setLogout());
        navigate("/auth", { replace: true });
        return;
      } else if (err.internalCode == 4032) {
        setDialogData({
          title: "خطا",
          content: err.message,
          close: {
            action: () => undefined,
            closeOnClick: false,
          },
          buttons: [
            {
              text: "تایید",
              action: () => {
                navigate("/auth", { replace: true });
              },
              closeOnClick: true,
            },
          ],
        });
        setShowDialog(true);
        return;
      } else if (err.internalCode == 4033) {
        setDialogData({
          title: "خطا",
          content: err.message,
          close: {
            action: () => undefined,
            closeOnClick: false,
          },
          buttons: [
            {
              text: "تایید",
              action: () => {
                navigate("/auth", { replace: true });
              },
              closeOnClick: true,
            },
          ],
        });
        setShowDialog(true);
        return;
      }
    }
    setDialogData({
      title: "خطا",
      content: "خطایی رخ داد. لطفا مجددا تلاش کنید",
      close: {
        action: () => undefined,
        closeOnClick: true,
      },
      buttons: [
        {
          text: "تایید",
          action: () => undefined,
          closeOnClick: true,
        },
      ],
    });
    setShowDialog(true);
  };

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  return (
    <Box>
      <Navbar />

      <DialogBox
        open={showDialog}
        setOpen={setShowDialog}
        title={dialogData.title}
        content={dialogData.content}
        close={dialogData.close}
        buttons={dialogData.buttons}
      ></DialogBox>

      <Box
        className="container"
        width={isNonMobileScreens ? "80%" : "96%"}
        maxHeight
        marginTop="1rem"
        marginLeft={isNonMobileScreens ? "10%" : "2%"}
      >
        {videoSrc !== "" ? (
          <VideoJS
            src={videoSrc}
            poster={poster}
            productId={productId}
            isSaved={isSaved}
          />
        ) : (
          ""
        )}
      </Box>
      <Box height="5rem"></Box>
    </Box>
  );
};

export default VideoPage;
