import { useState, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthData } from "state";
import JSONBig from "json-bigint";
import DialogBox from "components/DialogBox";
// import ReCAPTCHA from "react-google-recaptcha";

const registerSchema = yup.object().shape({
  email: yup
    .string()
    .email("ایمیل معتبر نیست")
    .required("ایمیل خود رو وارد کنید"),
  password: yup
    .string()
    .min(6, "حداقل 6 حرف")
    .required("رمز عبور را وارد کنید"),
  confirmPassword: yup
    .string()
    .required("لطفا تکرار رمز عبور را وارد کنید")
    .oneOf([yup.ref("password")], "تکرار رمز عبور صحیح نیست"),
});

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("ایمیل معتبر نیست")
    .required("ایمیل خود رو وارد کنید"),
  password: yup.string().required("رمز عبور را وارد کنید"),
});

const initialValuesRegister = {
  email: "",
  password: "",
  confirmPassword: "",
};

const initialValuesLogin = {
  email: "",
  password: "",
};

const Form = () => {
  const BaseURL = process.env.REACT_APP_BASE_URL;

  const [dialogData, setDialogData] = useState({
    title: "",
    content: "",
    close: {
      action: null,
      closeOnClick: true,
    },
    buttons: [],
  });
  const [showDialog, setShowDialog] = useState(false);
  const handleError = (err) => {
    if (err && err.internalCode) {
      setDialogData({
        title: "خطا",
        content: err.message,
        close: {
          action: () => undefined,
          closeOnClick: true,
        },
        buttons: [
          {
            text: "تایید",
            action: () => undefined,
            closeOnClick: true,
          },
        ],
      });
      setShowDialog(true);
      return;
    }
    setDialogData({
      title: "خطا",
      content: "خطایی رخ داد. لطفا مجددا تلاش کنید",
      close: {
        action: () => undefined,
        closeOnClick: true,
      },
      buttons: [
        {
          text: "تایید",
          action: () => undefined,
          closeOnClick: true,
        },
      ],
    });
    setShowDialog(true);
  };

  const captchaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageType, setPageType] = useState("login");
  const [sendText, setSendText] = useState("ارسال");
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const isRegister = pageType === "register";
  const isReset = pageType === "reset";

  const setSendTimer = () => {
    let waitTime = 121;
    const interval = setInterval(() => {
      if (waitTime === 1 || waitTime === "Send") {
        waitTime = "Send";
      } else {
        waitTime--;
      }
      setSendText(waitTime.toString());
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setSendText("ارسال");
    }, 121 * 1000);
  };

  const forgotPasswordApi = async (body) => {
    try {
      const resp = await fetch(BaseURL + "/auth/forgot", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const respStr = await resp.text();
      const json = JSONBig.parse(respStr);
      if (json.error || resp.status >= 400) {
        throw json.error;
      } else {
        setSendTimer();
      }
    } catch (e) {
      handleError(e);
    }
  };

  const resetPasswordApi = async (values, onSubmitProps) => {
    try {
      setIsLoading(true);
      values.code = values.code.toString();
      const resp = await fetch(BaseURL + "/auth/reset", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });
      const respStr = await resp.text();
      const json = JSONBig.parse(respStr);
      // console.log("loggedin data: ", json);
      if (json.error) {
        throw json.error;
      } else {
        setIsLoading(false);
        if (json && json.data && json.data.userId) {
          dispatch(
            setAuthData({
              user: {
                email: values.email,
                userId: json.data.userId.toString(),
              },
              token: json.data.token,
            })
          );
          navigate("/home", { replace: true });
        }
      }
    } catch (e) {
      handleError(e);
      setIsLoading(false);
    }
  };

  const register = async (values, onSubmitProps) => {
    // this allows us to send form info with image
    try {
      setIsLoading(true);
      const resp = await fetch(BaseURL + "/auth/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });
      const respStr = await resp.text();
      const json = JSONBig.parse(respStr);
      // console.log("loggedin data: ", json);
      if (json.error) {
        throw json.error;
      } else {
        setIsLoading(false);
        if (json && json.data && json.data.userId) {
          dispatch(
            setAuthData({
              user: {
                email: values.email,
                userId: json.data.userId.toString(),
              },
              token: json.data.token,
            })
          );
          navigate("/home", { replace: true });
        }
      }
    } catch (e) {
      handleError(e);
      setIsLoading(false);
    }
  };

  const login = async (values, onSubmitProps) => {
    // console.log("login values: ", values);
    try {
      setIsLoading(true);
      const resp = await fetch(BaseURL + "/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });
      const respStr = await resp.text();
      const json = JSONBig.parse(respStr);
      // console.log("loggedin data: ", json);
      if (json.error) {
        throw json.error;
      } else {
        setIsLoading(false);
        if (json && json.data && json.data.userId) {
          dispatch(
            setAuthData({
              user: {
                email: values.email,
                userId: json.data.userId.toString(),
              },
              token: json.data.token,
            })
          );
          navigate("/home", { replace: true });
        }
      }
    } catch (e) {
      handleError(e);
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    // values.response = captchaRef.current.getValue();

    if (isLogin) await login(values, onSubmitProps);
    if (isRegister) await register(values, onSubmitProps);
    if (isReset) await resetPasswordApi(values, onSubmitProps);

    // onSubmitProps.resetForm();
    captchaRef.current.reset();
  };

  const handleOnSend = async (email) => {
    if (ValidateEmail(email)) {
      await forgotPasswordApi({ email });
    }
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={isLogin ? initialValuesLogin : {}}
        validationSchema={isLogin ? loginSchema : registerSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {isRegister && (
                <>
                  <TextField
                    label="ایمیل"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="رمز عبور"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={
                      Boolean(touched.password) && Boolean(errors.password)
                    }
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="تکرار رمز عبور"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    error={
                      Boolean(touched.confirmPassword) &&
                      Boolean(errors.confirmPassword) &&
                      values.password !== values.confirmPassword
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    sx={{ gridColumn: "span 4" }}
                  />
                </>
              )}
              {isLogin && (
                <>
                  <TextField
                    label="ایمیل"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="رمز عبور"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={
                      Boolean(touched.password) && Boolean(errors.password)
                    }
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 4" }}
                  />
                </>
              )}
              {isReset && (
                <>
                  <TextField
                    label="ایمیل"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <Button
                    disabled={sendText !== "ارسال"}
                    sx={{
                      m: "0 0 2rem 0",
                      p: "1rem",
                      gridColumn: "span 2",
                      backgroundColor: palette.primary.main,
                      color: palette.background.alt,
                      "&:hover": { color: palette.primary.dark },
                      "&:disabled": { color: palette.background.alt },
                    }}
                    onClick={() => handleOnSend(values.email)}
                  >
                    {sendText}
                  </Button>
                  <TextField
                    label="کد"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.code}
                    name="code"
                    error={Boolean(touched.code) && Boolean(errors.code)}
                    helperText={touched.code && errors.code}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="رمز عبور"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={
                      Boolean(touched.password) && Boolean(errors.password)
                    }
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="تکرار رمز عبور"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    error={
                      Boolean(touched.confirmPassword) &&
                      Boolean(errors.confirmPassword) &&
                      values.password !== values.confirmPassword
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    sx={{ gridColumn: "span 4" }}
                  />
                </>
              )}
              {/* <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
              /> */}
            </Box>

            {/* BUTTONS */}
            <Box>
              <Button
                fullWidth
                type="submit"
                disabled={isLoading}
                sx={{
                  m: "2rem 0",
                  p: "1rem",
                  backgroundColor: palette.button.main,
                  color: palette.button.text,
                  "&:hover": { color: palette.button.hover },
                  "&:disabled": { color: palette.background.alt },
                  fontSize: "16px",
                }}
              >
                {isLogin
                  ? "ورود"
                  : isRegister
                  ? "ثبت نام"
                  : isReset
                  ? "ثبت"
                  : "مشکلی پیش آمده"}
              </Button>
              <Typography
                fontSize="20px"
                onClick={() => {
                  setPageType(isLogin ? "register" : "login");
                  resetForm();
                }}
                sx={{
                  // textDecoration: "underline",
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "pointer",
                    color: palette.primary.dark,
                  },
                }}
              >
                {isLogin
                  ? "حساب کاربری ندارید؟ ثبت نام کنید"
                  : "حساب کاربری دارید؟ وارد شوید"}
              </Typography>
              <Typography
                fontSize="20px"
                onClick={() => {
                  setPageType("reset");
                  resetForm();
                }}
                sx={{
                  // textDecoration: "underline",
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "pointer",
                    color: palette.primary.light,
                  },
                }}
              >
                رمز عبور خود را فراموش کردید؟
              </Typography>
            </Box>
          </form>
        )}
      </Formik>

      <DialogBox
        open={showDialog}
        setOpen={setShowDialog}
        title={dialogData.title}
        content={dialogData.content}
        close={dialogData.close}
        buttons={dialogData.buttons}
      ></DialogBox>
    </>
  );
};

export default Form;

function ValidateEmail(input) {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (input.match(validRegex)) {
    return true;
  }

  return false;
}
