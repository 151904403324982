import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setLogout } from "state";

const DialogBox = ({
  open,
  setOpen,
  buttons,
  title,
  content,
  close,
  err = null,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogData, setDialogData] = useState({
    title,
    content,
    close,
    buttons,
  });

  useEffect(() => {
    if (err) {
      console.log(err);
      handleError(err);
    }
  }, [err]);

  const handleError = (err) => {
    if (err && err.internalCode) {
      if (
        err.internalCode == 4010 ||
        err.internalCode == 4011 ||
        err.internalCode == 4043
      ) {
        dispatch(setLogout());
        navigate("/auth", { replace: true });
        return;
      } else if (err.internalCode == 4032) {
        setDialogData({
          title: "خطا",
          content: err.message,
          close: {
            action: () => undefined,
            closeOnClick: false,
          },
          buttons: [
            {
              text: "تایید",
              action: () => {
                navigate("/auth", { replace: true });
              },
              closeOnClick: true,
            },
          ],
        });
        setOpen(true);
        return;
      } else if (err.internalCode == 4033) {
        setDialogData({
          title: "خطا",
          content: err.message,
          close: {
            action: () => undefined,
            closeOnClick: false,
          },
          buttons: [
            {
              text: "تایید",
              action: () => {
                navigate("/auth", { replace: true });
              },
              closeOnClick: true,
            },
          ],
        });
        setOpen(true);
        return;
      }
    }
    setDialogData({
      title: "خطا",
      content: "خطایی رخ داد. لطفا مجددا تلاش کنید",
      close: {
        action: () => undefined,
        closeOnClick: true,
      },
      buttons: [
        {
          text: "تایید",
          action: () => undefined,
          closeOnClick: true,
        },
      ],
    });
    setOpen(true);
  };

  return (
    <>
      <Dialog
        key={1}
        open={open}
        onClose={() => {
          dialogData.close.action();
          if (dialogData.close.closeOnClick) {
            setOpen(false);
          }
        }}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-content"
      >
        <DialogTitle id="dialog-title">{dialogData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-content">
            {dialogData.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogData.buttons.map((btn, i) => (
            <Button
              key={i}
              onClick={() => {
                btn.action();
                if (btn.closeOnClick) {
                  setOpen(false);
                }
              }}
            >
              {btn.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogBox;

// const [dialogData, setDialogData] = useState({
//   title: "",
//   content: "",
//   close: {
//     action: null,
//     closeOnClick: true,
//   },
//   buttons: [],
// });
