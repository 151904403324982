import { Button, useTheme, Stack } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import { useSelector } from "react-redux";
import "video.js/dist/video-js.css";
import JSONBig from "json-bigint";

export const VideoJS = ({ src, poster, productId, isSaved }) => {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const state = useSelector((state) => state);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const options = {
    src: { src: src, type: "video/mp4" },
    poster: poster,
    controls: true,
    autoplay: false,
    responsive: true,
    // width: "100%",
    // aspectRatio: "16:9",
    fluid: true,
  };

  const lastTouchEnd = useRef(0);
  const SKIP_AMOUNT = 5;

  const { palette } = useTheme();

  useEffect(() => {
    const handleKeyDown = (e) => {
      const player = playerRef.current;

      if (!player) return;

      switch (e.code) {
        case "Space":
          player.paused() ? player.play() : player.pause();
          break;
        case "ArrowRight":
          player.currentTime(player.currentTime() + SKIP_AMOUNT);
          break;
        case "ArrowLeft":
          player.currentTime(player.currentTime() - SKIP_AMOUNT);
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handlePlayerReady = (player) => {
    player.on("touchend", (e) => {
      const currentTime = Date.now();
      const delta = currentTime - lastTouchEnd.current;

      if (delta < 300) {
        // Double tap detected
        const { width } = player.currentDimensions();
        const touchX =
          e.changedTouches[0].clientX -
          player.el().getBoundingClientRect().left;

        if (touchX < width * 0.3) {
          // Left part of the player
          player.currentTime(player.currentTime() - SKIP_AMOUNT);
        } else if (touchX > width * 0.7) {
          // Right part of the player
          player.currentTime(player.currentTime() + SKIP_AMOUNT);
        } else {
          // Middle part of the player
          player.paused() ? player.play() : player.pause();
        }
      }
      lastTouchEnd.current = currentTime;
    });
  };

  useEffect(() => {
    if (!playerRef.current) {
      const player = (playerRef.current = videojs(
        videoRef.current,
        options,
        () => {
          handlePlayerReady(player);
        }
      ));
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
      player.poster(options.poster);
    }
  }, [src, poster]); // Add src and poster to dependency array

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const [isProductSaved, setIsProductSaved] = useState(isSaved);
  const [isSavedDisable, setIsSavedDisable] = useState(false);

  const savedOnClick = async () => {
    setIsSavedDisable(true);
    try {
      if (isProductSaved) {
        await deleteSaveRequest();
      } else {
        await saveRequest();
      }
    } catch (error) {}
    setIsSavedDisable(false);
  };

  const saveRequest = async () => {
    const resp = await fetch(BaseURL + "/user/saved", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
      body: JSONBig.stringify({ productId: BigInt(productId) }),
    });
    if (resp.status == 200) {
      setIsProductSaved(true);
    }
  };

  const deleteSaveRequest = async () => {
    const resp = await fetch(BaseURL + "/user/saved/" + productId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
    });
    if (resp.status == 200) {
      setIsProductSaved(false);
    }
  };

  return (
    <>
      <div data-vjs-player>
        <video
          src={src}
          ref={videoRef}
          className="video-js vjs-big-play-centered"
        ></video>
      </div>
      <Stack spacing={2} direction="row">
        <Button
          variant={isProductSaved ? "outlined" : "contained"}
          color={isProductSaved ? "warning" : "warning"}
          fullWidth
          disabled={isSavedDisable}
          onClick={savedOnClick}
          sx={{
            marginTop: "1rem",
            height: "2rem",
            fontSize: "16px",
          }}
        >
          {isProductSaved ? "ذخیره شده" : "ذخیره"}
        </Button>
      </Stack>
    </>
  );
};

export default VideoJS;
