import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import LoginPage from "scenes/loginPage";
import HomePage from "scenes/homePage";
import PlaylistPage from "scenes/playlistPage";
import VideoPage from "scenes/videoPage";
import GalleryPage from "scenes/galleryPage";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <>
      <div className="app">
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/auth" element={<LoginPage />} />
              <Route
                path="/home"
                element={<HomePage />}
                // element={isAuth ? <HomePage /> : <Navigate to="/auth" />}
              />
              <Route
                path="/"
                element={<HomePage />}
                // element={isAuth ? <HomePage /> : <Navigate to="/auth" />}
              />
              <Route
                path="/playlist"
                element={isAuth ? <PlaylistPage /> : <Navigate to="/" />}
              />
              <Route
                path="/video/:videoId"
                element={isAuth ? <VideoPage /> : <Navigate to="/" />}
              />
              <Route
                path="/gallery/:productId"
                element={isAuth ? <GalleryPage /> : <Navigate to="/" />}
              />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
