import {
  Dialog,
  Stack,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CreatePlaylistDialog from "./CreatePlaylistDialog";
import DialogBox from "./DialogBox";
import { setPlaylistId, setSaved } from "state";
import {
  addProductToPlaylist,
  removeProductFromPlaylist,
  saveProduct,
  unsaveProduct,
} from "api/playlist";

const PlaylistDialog = ({
  open,
  onClose,
  productId,
  productPlaylistIds,
  isSaved,
}) => {
  const [newPlaylistDialogOpen, setNewPlaylistDialogOpen] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const handleOpenNewPlaylistDialog = () => {
    setNewPlaylistDialogOpen(true);
  };

  const handleCloseNewPlaylistDialog = () => {
    setNewPlaylistDialogOpen(false);
  };

  const saveToPlaylist = async (playlistId) => {
    try {
      await addProductToPlaylist(state.token, playlistId, productId);
      dispatch(setPlaylistId({ playlistId, productId, add: true }));
    } catch (e) {
      setError(e);
    }
  };

  const deleteFromPlaylist = async (playlistId) => {
    try {
      await removeProductFromPlaylist(state.token, playlistId, productId);
      dispatch(setPlaylistId({ playlistId, productId, add: false }));
    } catch (e) {
      setError(e);
    }
  };

  const saveProductEvent = async () => {
    try {
      await saveProduct(state.token, productId);
      dispatch(setSaved({ isSaved: true, productId }));
    } catch (e) {
      setError(e);
    }
  };

  const unsaveProductEvent = async () => {
    try {
      await unsaveProduct(state.token, productId);
      dispatch(setSaved({ isSaved: false, productId }));
    } catch (e) {
      setError(e);
    }
  };

  return (
    <>
      <DialogBox
        open={showDialog}
        setOpen={setShowDialog}
        title=""
        content=""
        close={{}}
        buttons={[]}
        err={error}
      ></DialogBox>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
        sx={{ justifyContent: "center" }}
      >
        <Stack direction="column" spacing={3} sx={{ padding: "3rem 20%" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={handleOpenNewPlaylistDialog}
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
            >
              پلی لیست جدید
            </Button>
          </Box>

          <List
            sx={{
              maxHeight: 200,
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#1976d2",
                outline: "1px solid slategrey",
              },
            }}
          >
            <ListItem key="-1" dense>
              <Checkbox
                edge="start"
                defaultChecked={isSaved}
                onChange={(e, checked) =>
                  checked ? saveProductEvent() : unsaveProductEvent()
                }
              />
              <ListItemText primary="مورد علاقه ها" />
            </ListItem>
            {state.playlists.map((playlist) => (
              <ListItem key={playlist.id} dense>
                <Checkbox
                  edge="start"
                  defaultChecked={productPlaylistIds.indexOf(playlist.id) > -1}
                  onChange={(e, checked) =>
                    checked
                      ? saveToPlaylist(playlist.id)
                      : deleteFromPlaylist(playlist.id)
                  }
                />
                <ListItemText primary={playlist.name} />
              </ListItem>
            ))}
          </List>

          <Button variant="contained" color="primary" onClick={() => onClose()}>
            بستن
          </Button>
        </Stack>
      </Dialog>

      <CreatePlaylistDialog
        open={newPlaylistDialogOpen}
        onClose={handleCloseNewPlaylistDialog}
      ></CreatePlaylistDialog>
    </>
  );
};

export default PlaylistDialog;
