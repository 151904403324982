import { get, post, put, deleteBe } from "../index";
import { processProducts } from "utils";

export async function getAllProducts(token, body) {
  const resp = await post({
    path: `/product/all`,
    body,
    token,
  });
  if (resp?.data?.products)
    resp.data.products = processProducts(resp.data.products);
  return resp.data;
}

export async function getAllFilters(token) {
  const resp = await get({
    path: `/product/filter`,
    token,
  });
  if (resp?.data?.models)
    resp.data.models = resp.data.models.map((m) => {
      m.id = m.id.toString();
      return m;
    });
  if (resp?.data?.tags)
    resp.data.tags = resp.data.tags.map((t) => {
      t.id = t.id.toString();
      return t;
    });
  return resp.data;
}
