import { Box, useMediaQuery, Pagination } from "@mui/material";
import Navbar from "scenes/navbar";
import SearchBar from "components/SearchBar";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import ProductComponent from "components/Product";

const HomePage = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  let urlPage = searchParams.get("page") || 1;
  urlPage = Number(urlPage);
  if (isNaN(urlPage) || urlPage < 1) {
    urlPage = 1;
  }
  const [page, setPage] = useState(urlPage);
  const handlePageChange = (event, value) => {
    setPage(value);
    const queryParams = {};
    for (const key of searchParams.keys()) {
      queryParams[key] = searchParams.get(key);
    }
    queryParams["page"] = value;
    setSearchParams(queryParams);
    // window.location.replace(
    //   utils.getDomainName() + "/home" + utils.getQueryString(queryParams)
    // );
    window.scrollTo(0, 0);
  };

  const state = useSelector((state) => state);
  const [loggedIn, setLoggedIn] = useState(false);

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  return (
    <Box>
      <Navbar />
      <SearchBar setLoggedIn={setLoggedIn}></SearchBar>
      <Box
        className="pagination"
        width={isNonMobileScreens ? "60%" : "90%"}
        marginTop="1rem"
        marginLeft={isNonMobileScreens ? "20%" : "5%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Pagination
          count={
            state.totalProducts > 0
              ? Math.floor((state.totalProducts - 1) / 10) + 1
              : 0
          }
          page={page}
          siblingCount={5}
          onChange={handlePageChange}
          sx={{ display: "table-cell" }}
        />
      </Box>

      <Box
        className="container"
        width={isNonMobileScreens ? "50%" : "90%"}
        marginTop="1rem"
        marginLeft={isNonMobileScreens ? "25%" : "5%"}
        // display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        {state?.products?.length > 0 ? (
          state.products.map((product, index) => (
            <ProductComponent
              product={product}
              isLoggedIn={loggedIn}
              key={index}
            />
          ))
        ) : (
          <Box className="empty">
            <h2>No videos found</h2>
          </Box>
        )}
      </Box>
      <Box
        className="pagination"
        width={isNonMobileScreens ? "60%" : "90%"}
        marginTop="1rem"
        marginLeft={isNonMobileScreens ? "20%" : "5%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Pagination
          count={
            state.totalProducts > 0
              ? Math.floor((state.totalProducts - 1) / 10) + 1
              : 0
          }
          page={page}
          siblingCount={5}
          onChange={handlePageChange}
          sx={{ display: "table-cell" }}
        />
      </Box>
      <Box height="5rem"></Box>
    </Box>
  );
};

export default HomePage;
