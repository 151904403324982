import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  user: null,
  token: null,
  lastTokenUpdate: 0,
  products: [],
  totalProducts: 0,
  models: [],
  tags: [],
  websites: [],
  playlists: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setAuthData: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.lastTokenUpdate = Math.floor(Date.now() / 1000);
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.lastTokenUpdate = 0;
    },
    setProducts: (state, action) => {
      if (action.payload.products) {
        state.products = action.payload.products;
        state.totalProducts = action.payload.total;
      }
    },
    setPlaylists: (state, action) => {
      if (action.payload.playlists) {
        state.playlists = action.payload.playlists;
      }
    },
    setFilters: (state, action) => {
      if (action.payload.models) {
        state.models = action.payload.models;
      }
      if (action.payload.tags) {
        state.tags = action.payload.tags;
      }
      if (action.payload.websites) {
        state.websites = action.payload.websites;
      }
    },
    setSaved: (state, action) => {
      const { isSaved, productId } = action.payload;
      state.products = state.products.map((p) => {
        if (p.id === productId) {
          p.isSaved = isSaved;
        }
        return p;
      });
    },
    setPlaylistId: (state, action) => {
      const { playlistId, productId, add } = action.payload;
      state.products = state.products.map((p) => {
        if (p.id === productId) {
          if (add) {
            if (p.playlistIds.indexOf(playlistId) === -1)
              p.playlistIds.push(playlistId)
          } else {
            const index = p.playlistIds.indexOf(playlistId)
            if (index >= 0)
              p.playlistIds.splice(index, 1);
          }
        }
        return p;
      });
    },
  },
});

export const {
  setMode,
  setAuthData,
  setLogout,
  setFilters,
  setProducts,
  setSaved,
  setPlaylists,
  setPlaylistId
} = authSlice.actions;
export default authSlice.reducer;
