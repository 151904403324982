import { Dialog, Stack, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";

const SubscriptionDialog = (props) => {
  const { open, onClose } = props;
  const [active, setActive] = useState(true);

  const BaseURL = process.env.REACT_APP_BASE_URL;
  const state = useSelector((state) => state);

  const buy1SubscriptionOnClick = async () => {
    setActive(false);
    try {
      const url = await getSubscriptionUrl("sub_1");
      window.location.replace(url);
    } catch (error) {}
    setActive(true);
  };
  const buy3SubscriptionOnClick = async () => {
    setActive(false);
    try {
      const url = await getSubscriptionUrl("sub_3");
      window.location.replace(url);
    } catch (error) {}
    setActive(true);
  };
  const buy12SubscriptionOnClick = async () => {
    setActive(false);
    try {
      const url = await getSubscriptionUrl("sub_12");
      window.location.replace(url);
    } catch (error) {}
    setActive(true);
  };

  const getSubscriptionUrl = async (item) => {
    const resp = await fetch(BaseURL + "/plisio/invoice", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
      body: JSON.stringify({
        item: item,
      }),
    });
    const respStr = await resp.text();
    const json = JSON.parse(respStr);
    if (json.error) {
      throw json.error;
    } else {
      return json.data.url;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
        sx={{ justifyContent: "center" }}
      >
        <Stack
          direction="column"
          spacing={5}
          marginLeft="20%"
          marginRight="20%"
          marginTop="3rem"
          marginBottom="3rem"
        >
          {/* <Button
            variant="contained"
            color="success"
            disabled={!active}
            fullWidth
            onClick={buy1SubscriptionOnClick}
            sx={{ borderRadius: "16px" }}
          >
            اشتراک ۱ ماهه (۲۹ دلار)
          </Button> */}
          <Button
            variant="contained"
            color="success"
            disabled={!active}
            fullWidth
            onClick={buy3SubscriptionOnClick}
            sx={{ borderRadius: "16px" }}
          >
            اشتراک ۳ ماهه (69 دلار)
          </Button>
          <Button
            variant="contained"
            color="success"
            disabled={!active}
            fullWidth
            onClick={buy12SubscriptionOnClick}
            sx={{ borderRadius: "16px" }}
          >
            اشتراک یک ساله (99 دلار)
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default SubscriptionDialog;
