import {
  Dialog,
  Stack,
  Button,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import DialogBox from "./DialogBox";
import { createPlaylist, getPlaylists } from "api/playlist";
import { setPlaylists } from "state";

const CreatePlaylistDialog = ({ open, onClose }) => {
  const [newPlaylist, setNewPlaylist] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const handleNewPlaylistChange = (e) => {
    setNewPlaylist(e.target.value);
  };

  const handleCloseNewPlaylistDialog = () => {
    onClose();
  };

  const handleCreatePlaylist = async () => {
    try {
      await createPlaylist(state.token, newPlaylist);
      const playlists = await getPlaylists(state.token);
      console.log(playlists);
      dispatch(
        setPlaylists({
          playlists,
        })
      );
      onClose();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <>
      <DialogBox
        open={showDialog}
        setOpen={setShowDialog}
        title=""
        content=""
        close={{}}
        buttons={[]}
        err={error}
      ></DialogBox>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <Stack direction="column" spacing={2} sx={{ padding: "2rem" }}>
          <Typography variant="h6">پلی لیست جدید</Typography>
          <TextField
            fullWidth
            label="نام"
            value={newPlaylist}
            onChange={handleNewPlaylistChange}
            variant="outlined"
            size="small"
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={handleCloseNewPlaylistDialog} color="secondary">
              لغو
            </Button>
            <Button
              onClick={handleCreatePlaylist}
              color="primary"
              variant="contained"
            >
              ذخیره
            </Button>
          </Box>
        </Stack>
      </Dialog>
    </>
  );
};

export default CreatePlaylistDialog;
