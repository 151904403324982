import { useState } from "react";
import {
  Box,
  IconButton,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setLogout } from "state";
import { useNavigate } from "react-router-dom";
import Link from "components/Link";

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery(theme.breakpoints.up("md"));

  const handleLogout = () => {
    dispatch(setLogout());
    navigate("/auth", { replace: true });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ p: 2, backgroundColor: theme.palette.background.alt }}
    >
      <Grid item>
        <Link
          to="/home"
          variant="h6"
          color="primary"
          sx={{
            textDecoration: "none",
            ":hover": { color: theme.palette.primary.light },
          }}
        >
          Private Stream
        </Link>
      </Grid>

      <Grid item>
        {isNonMobileScreens ? (
          // Desktop Menu Items
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" onClick={() => navigate("/home")}>
                صفحه اصلی
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => navigate("/playlist")}>
                پلی لیست
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleLogout}>
                خروج از حساب کاربری
              </Button>
            </Grid>
          </Grid>
        ) : (
          <IconButton
            onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
          >
            <Menu />
          </IconButton>
        )}
      </Grid>

      {isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          width="80%"
          backgroundColor={theme.palette.background.default}
          sx={{ [theme.breakpoints.up("md")]: { width: "30%" } }}
        >
          <Box display="flex" justifyContent="flex-end" p={1}>
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          {/* Mobile Menu Items */}
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid item>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => navigate("/home")}
              >
                صفحه اصلی
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => navigate("/playlist")}
              >
                پلی لیست
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" fullWidth onClick={handleLogout}>
                خروج از حساب کاربری
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default Navbar;
